<template>
    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

        <v-col class="py-0" cols="12" sm="12">
            <div>

              <v-sheet tile class="d-flex justify-end">

                  <v-icon color="primary" @click="$refs.calendar.prev()">
                    mdi-chevron-left
                  </v-icon>

                  <div v-if="$refs.calendar" class="calendar">
                      {{ $refs.calendar.title }}
                  </div>

                  <v-icon color="primary"
                          @click="$refs.calendar.next()">
                    mdi-chevron-right
                  </v-icon>


              </v-sheet>
                <v-calendar
                    ref="calendar"
                    type="month"
                    event-overlap-mode="stack"
                    v-model="to_do_calendar"
                    full-width
                    class="calendar mt-4 "
                    color="primary"
                    @input="setCalendarEvents"
                    :events="calendarEvents"
                    :weekdays="weekday"
                    @contextmenu:date="showContextMenu"
                    @click:event="showEvent"


                >


                </v-calendar>
            </div>
            <v-card v-if="filteredEvents.length > 0">
                <v-card-title>{{ filteredEvents[0].date_text }}</v-card-title>
                <v-card-text>
                    <ul>
                        <li v-for="event in filteredEvents" :key="event.uuid">
                            <strong>{{ event.title }}</strong>
                            <p>{{ event.description }}</p>
                        </li>
                    </ul>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            class="my-2 px-1"
            cols="12"
            sm="12"
        >
            <v-menu
                v-model="showMenu"
                :position-x="menuX"
                :position-y="menuY"
                offset-x
                absolute
            >
                <v-list>
                    <v-list-item v-if="!($moment().unix() > $moment(date_case).unix()) || ($moment().format('DD.MM.YYYY') === $moment(date_case).format('DD.MM.YYYY'))" @click="createEvent(true)">{{$t('add_case')}}</v-list-item>
                    <v-list-item v-if="false" @click="deleteEvent">{{$t('delete')}}</v-list-item>
                </v-list>
            </v-menu>
        </v-col>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="900"
            v-model="dialogCase"
            class="dialog_body"
            persistent
        >
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ edit_case ? $t('edit_case') : $t('add_case') }}</span>
                        </div>
                        <div>
                            <v-icon @click="closeCaseDialog()" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <v-row class="py-0">
                                <v-col cols="12" sm="12" class="py-0">
                                    <ValidationProvider ref="case_type" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('case_type')}}</div>
                                        <v-select v-model="case_type" :disabled="loading || result"
                                                  :items="caseTypeItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('case_type')" clearable
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  hide-details
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  append-outer-icon="mdi-plus"
                                                  @click:append-outer="dialogFormCaseType=true;case_type_name=null"
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="12" class="py-0">
                                    <ValidationProvider ref="name" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('name')}}</div>
                                        <v-text-field v-model="name" type="text" :disabled="loading"
                                                      :error="!valid" :error-messages="errors"
                                                      :label="$t('name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="12" class="py-0">
                                    <ValidationProvider ref="case_text" rules="max:62000"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('description')}}</div>
                                        <v-textarea

                                            v-model="case_text"
                                            type="text"
                                            rows="1"
                                            :label="$t('description')"
                                            :disabled="loading || result"
                                            color="primary"
                                            auto-grow
                                            clearable outlined
                                            hide-details
                                            full-width
                                            background-color="white lighten-2"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                        ></v-textarea>
                                    </ValidationProvider>
                                </v-col>


                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="responsible" rules="required|min:1" v-slot="{ errors, valid }">
                                        <UserSelection
                                            v-model="responsible"
                                            id="responsible"
                                            :label="$t('responsible')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                        />
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="participant" rules="min:1" v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('participants')}}</div>
                                        <v-combobox v-model="participant" :items="participantItems"
                                                    :error="!valid"
                                                    :search-input.sync="participantSearching" hide-selected
                                                    item-text="name" item-value="id" :loading="loadingParticipants"
                                                    :label="$t('participants')" @click:clear="participantItems=[]"
                                                    @click="clearParticipants" @input="clearEmptyParticipantItems(participant)"
                                                    multiple return-object autocomplete="off"
                                                    hide-details
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                    clearable>
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search_list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty" v-text="item.phone"></span>
                                                    </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <v-chip v-if="item === Object(item)"
                                                        class="input_chip"
                                                        v-bind="attrs" :input-value="selected"
                                                        @click:close="parent.selectItem(item)"
                                                        :color="item.active ? 'green  lighten-5' : 'red  lighten-5'"
                                                        :title="item.active ? $t('active') : $t('no_active')"
                                                        link close>
                                                    <span v-html="item.name"></span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ participantSearching ? $t('nothing_found_by', {'search': participantSearching}) : $t('nothing_found_name')}}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-combobox>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="4" class="py-0">
                                    <v-dialog ref="dateDialogCase" v-model="dateDialogCase" width="290px">
                                        <v-date-picker ref="pickerDateContract" v-model="date_case"
                                                       first-day-of-week="1" :locale="lang"
                                                       :min="$moment().format('YYYY-MM-DD')"
                                                       @change="dateDialogCase = false">
                                        </v-date-picker>
                                    </v-dialog>
                                    <ValidationProvider ref="dateContract" rules="required|date_format"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('date_case')}}</div>
                                        <v-text-field v-model="date_case" :error-messages="errors"
                                                      :error="!valid" :disabled="loading || result"
                                                      :label="$t('date_case')"
                                                      readonly
                                                      @click.stop="dateDialogCase = !dateDialogCase"
                                                      clearable @click:clear="date_case = null"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="4" class="py-0">
                                    <v-menu v-model="TimeFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <ValidationProvider ref="time_from" name="time_from" rules="required|min:4|max:5" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('time_from')}}</div>
                                                <v-text-field v-model="time_from" :error-messages="errors"
                                                              :error="!valid"
                                                              :disabled="loading" :label="$t('time_from')"
                                                              color="primary" readonly v-on="on"

                                                              clearable @click:clear="time_from = null"

                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              hide-details
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"

                                                ></v-text-field>
                                            </ValidationProvider>
                                        </template>
                                        <v-time-picker ref="picker" v-model="time_from"
                                                       format="24hr"
                                                       :max="time_to ? time_to : ''"
                                                       :locale="lang" @change="TimeFromMenu = false"></v-time-picker>
                                    </v-menu>


                                </v-col>

                                <v-col cols="12" sm="4" class="py-0">
                                    <v-menu v-model="TimeToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <ValidationProvider ref="time_to" name="time_to" rules="min:4|max:5" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('time_to')}}</div>
                                                <v-text-field v-model="time_to" :error-messages="errors" :error="!valid"
                                                              :disabled="loading" :label="$t('time_to')"
                                                              color="primary" readonly v-on="on"
                                                              clearable
                                                              @click:clear="time_to = null"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              hide-details
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"  >

                                                </v-text-field>
                                            </ValidationProvider>
                                        </template>
                                        <v-time-picker ref="time_to" v-model="time_to"
                                                       format="24hr"
                                                       :min="time_from ? time_from : ''"
                                                       :locale="lang"
                                                       @change="TimeToMenu = false">

                                        </v-time-picker>
                                    </v-menu>

                                </v-col>

                                <v-col cols="12" sm="12" class="py-0">
                                    <ValidationProvider ref="location_event" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('location_event')}}</div>
                                        <v-select v-model="location_event" :disabled="loading || result"
                                                  :items="locationEventItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('location_event')" clearable
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  hide-details
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  append-outer-icon="mdi-plus"
                                                  @click:append-outer="dialogFormLocationEvent=true;location_event_name=null"
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col  cols="12" sm="12">
                                    <v-menu
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :nudge-width="200"
                                        offset-y
                                        transition="scale-transition"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                {{$t('add_reminder')}}
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item

                                                v-for="(item, index) in reminderItems"
                                                :key="index"
                                                :disabled="item.disable"
                                                @click="selectItem(item)"
                                            >
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>


                                    <v-list-item
                                        v-for="item in selectedReminderItems"
                                        :key="item.name"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t('reminder_to_title', {'time': item.name})"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn icon :title="$t('delete')">
                                                <v-icon color="grey lighten-1" @click="deleteItem(item)">mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>

                                </v-col>



                            </v-row>
                            <v-row  v-if="!result && edit_case && ($moment().unix() >= $moment(date_case).unix())">
                                <v-col class="p5-0" cols="12">
                                    <v-btn
                                        class="button_link"
                                        plain
                                        text
                                        :disabled="userBlock || invalid || loading" :loading="loading"
                                        @click="formResult"
                                        color="green"
                                    >{{ $t('complete') }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row  v-if="result">
                                <v-col cols="12" sm="12">
                                    <ValidationProvider ref="case_status" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="case_status" :disabled="loading"
                                                  :items="caseStatusItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('case_status')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  hide-details
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        class="my-0 py-0"
                                        v-model="case_text_result"
                                        type="text"
                                        rows="1"
                                        auto-grow
                                        :label="$t('comment')"
                                        :disabled="loading"
                                        outlined
                                        row-height="25"
                                        no-details
                                        hide-details
                                        full-width
                                    >
                                        <template v-slot:append-outer>
                                            <v-icon @click="document.getElementById('case_file').click()">mdi-paperclip</v-icon>
                                        </template>

                                    </v-textarea>

                                    <v-file-input hide-input prepend-icon=""
                                                  v-model="case_files"
                                                  id="case_file"
                                                  multiple
                                                  @change="handleFileUpload"
                                    >
                                    </v-file-input>

                                </v-col>

                                <v-col
                                    v-for="(item, index) in selectedFiles"
                                    :key="index"
                                    cols="6"
                                >
                                    <v-card>
                                        <v-card-text>
                                            <v-icon
                                                @click="deleteCaseFile(item)"
                                                color="error"
                                            >
                                                mdi-delete
                                            </v-icon>
                                            {{ item.name }}
                                            <v-spacer></v-spacer>

                                        </v-card-text>

                                    </v-card>
                                </v-col>




                            </v-row>
                        </v-col>
                    </v-card-text>

                    <v-card-actions class="justify-end" v-if="!result">
                        <v-btn
                            text
                            :disabled="userBlock || invalid || loading" :loading="loading"
                            @click="addCaseAdmin"
                        >{{ $t('save') }}</v-btn>
                    </v-card-actions>
                    <v-card-actions class="justify-end" v-if="result">
                        <v-btn
                            text
                            @click="result = false"
                        >{{ $t('cancel') }}</v-btn>
                        <v-btn
                            text
                            :disabled="userBlock || invalid || loading" :loading="loading"
                            @click="addCaseAdmin"
                            color="green"
                        >{{ $t('complete') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogCaseResult"
        >
                <v-card >
                    <v-toolbar
                        color="primary"
                        dark
                    >{{ $t('case') }}</v-toolbar>
                    <v-card-text class="mx-0 px-0 mb-0 pb-0">
                        <v-col cols="12" class="mx-0 px-0 mb-0 pb-0">
                            <v-row class="py-0 mx-5">
                                <v-col cols="12" sm="12">
                                    <ValidationProvider ref="case_type" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="case_type" :disabled="true"

                                                  :items="caseTypeItems" :error-messages="errors"
                                                  prepend-icon="mdi-text-box-check-outline" :error="!valid"
                                                  color="primary" item-text="name" item-value="id"
                                                  :label="$t('case_type')" clearable></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <v-dialog ref="dateDialogCase" v-model="dateDialogCase" width="290px">
                                        <v-date-picker ref="pickerDateContract" v-model="date_case"
                                                       first-day-of-week="1" :locale="lang"
                                                       @change="dateDialogCase = false">
                                        </v-date-picker>
                                    </v-dialog>
                                    <ValidationProvider ref="dateContract" rules="required|date_format"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="date_case" :error-messages="errors"
                                                      :error="!valid" :disabled="true"
                                                      :label="$t('date_case')"
                                                      prepend-icon="mdi-calendar" color="primary" readonly
                                                      @click.stop="dateDialogCase = !dateDialogCase"
                                                      clearable @click:clear="date_case = null"></v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <v-menu v-model="onTimeMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <ValidationProvider ref="on_time" name="on_time" rules="min:4|max:5" v-slot="{ errors, valid }">
                                                <v-text-field v-model="on_time" :error-messages="errors"
                                                              :disabled="true" :label="$t('on_time')"
                                                              prepend-icon="mdi-clock-outline" color="primary" readonly v-on="on"

                                                              clearable @click:clear="on_time = null"></v-text-field>
                                            </ValidationProvider>
                                        </template>
                                        <v-time-picker ref="picker" v-model="on_time"
                                                       format="24hr"
                                                       :locale="lang" @change="onTimeMenu = false"></v-time-picker>
                                    </v-menu>


                                </v-col>

                                <v-col cols="12" sm="12" v-if="case_text">
                                    <v-textarea
                                        class="my-0 py-0"
                                        v-model="case_text"
                                        type="text"
                                        rows="1"
                                        auto-grow

                                        :label="$t('description')"
                                        :disabled="true"
                                    ></v-textarea>
                                </v-col>

                            </v-row>
                            <v-row class="mx-5 mb-0 pb-0" >
                                <v-col cols="12" sm="12">
                                    <ValidationProvider ref="case_status" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="case_status" :disabled="true"
                                                  :items="caseStatusItems" :error-messages="errors"
                                                  :error="!valid"
                                                  color="primary" item-text="name" item-value="id"
                                                  :label="$t('case_status')" clearable></v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="case_text_result">
                                    <v-textarea
                                        class="my-0 py-0"
                                        v-model="case_text_result"
                                        type="text"
                                        rows="1"
                                        auto-grow

                                        :label="$t('comment')"
                                        :disabled="true"
                                    >


                                    </v-textarea>




                                </v-col>

                                <v-col
                                    v-for="(item, index) in selectedFiles"
                                    :key="index"
                                    cols="6"
                                >
                                    <v-card @click="downloadFile(item)">
                                        <v-card-text>
                                            {{ item.name }}
                                            <v-spacer></v-spacer>

                                        </v-card-text>

                                    </v-card>
                                </v-col>




                            </v-row>
                        </v-col>
                    </v-card-text>
                    <v-divider class="mt-2"></v-divider>
                    <v-card-actions class="justify-end" >
                        <v-btn
                            text
                            @click="dialogCaseResult = false"
                        >{{ $t('close') }}</v-btn>

                    </v-card-actions>

                </v-card>

        </v-dialog>

        <v-dialog
            persistent
            max-width="1200px"
            transition="dialog-bottom-transition"
            v-model="dialogFormCaseType"
        >
            <v-form @submit.prevent="saveCaseType">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('case_type_creation') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeCaseType" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" >
                                    <ValidationProvider ref="case_type_name" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="case_type_name" type="text" :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('case_type_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>


                        </v-card-text>

                        <v-card-actions class="px-4 py-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeCaseType()"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>

        <v-dialog
            persistent
            max-width="1200px"
            transition="dialog-bottom-transition"
            v-model="dialogFormLocationEvent"
        >
            <v-form @submit.prevent="saveLocationEvent">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('location_event') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeLocationEvent" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" >
                                    <ValidationProvider ref="location_event_name" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="location_event_name" type="text" :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('location_event')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>


                        </v-card-text>

                        <v-card-actions class="px-4 py-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeLocationEvent()"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </ValidationObserver>
</template>

<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import debounce from "lodash/debounce";
    import UserSelection from "@/components/Form/UserSelection.vue";

    export default {
        name: 'CalendarCase',
        components: {
            UserSelection,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                calendarEvents: [],
                weekday: [1, 2, 3, 4, 5, 6, 0],
                to_do_calendar:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                filteredEvents: [],
                case_admin: [],
                showMenu: false,
                menuX: 0,
                menuY: 0,
                dialogCase: false,
                case_type: null,
                caseTypeItems: [],
                case_status: null,
                caseStatusItems: [],
                date_case: null,
                dateDialogCase: false,
                on_time : null,
                onTimeMenu: false,
                case_admin_id: null,
                dialogCaseResult:false,
                case_text: null,
                case_text_result: null,
                result:false,
                selectedFiles: [],
                edit_case: false,
                case_files:[],
                id_array: 0,
                type: 0,
                time_from : null,
                TimeFromMenu: false,
                time_to : null,
                TimeToMenu: false,
                location_event: null,
                locationEventItems: [],
                menu: false,
                reminderItems: [],
                selectedReminderItems: [],

                location_event_name: null,
                dialogFormLocationEvent: false,

                exist_translations: {},
                all_translations: true,

                dialogFormCaseType: false,
                case_type_name: null,

                name: null,
                responsible: null,
                responsibleItems: [],
                responsibleSearching: null,
                loadingResponsible: false,
                participant: null,
                participantItems: [],
                participantSearching: null,
                loadingParticipants: false,

            }
        },
        computed: {
            ...mapGetters(['userBlock','listLanguages', 'lang', 'timezone']),
            languages() {
                return this.listLanguages
            }
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getCaseType()
            await this.getCaseStatus()
            await this.getLocationEvent()
            await this.getReminder()
        },
        watch: {

            responsibleSearching: debounce(function (val) {
                if (val) {
                    this.getResponsibles(val)
                }
            }, 500),
            participantSearching: debounce(function (val) {
                if (val) {
                    this.getParticipants(val)
                }
            }, 500),
        },
        methods: {
            setLanguage(val) {
                this.language = this.languages[val]
            },
            handleFileUpload() {
                let i = this.selectedFiles.length
                let data = {}
                this.case_files.forEach((file) => {
                    data = {}
                    data.id = i
                    data.url = URL.createObjectURL(file)
                    data.name = file.name
                    data.blob =  file
                    data.size = file.size
                    data.type = file.type
                    this.selectedFiles.push(data)
                    i++
                })

            },
            deleteCaseFile(item){
                let index =  this.selectedFiles.indexOf(item)
                this.selectedFiles.splice(index, 1)
                if(this.selectedFiles.length === 0){
                    this.selectedFiles=[];

                }
            },
            setCalendarEvents() {
                this.filteredEvents = this.case_admin.filter(event => event.expiry_date === this.to_do_calendar);
            },
            async showEvent({ nativeEvent, event }){

                this.$router.push({
                    name: 'case.show',
                    params: {
                        id: event.uuid
                    }
                })

                // var _this = this;
                // this.progress = 0
                // this.loading = true
                // let params = {}
                // if (this.language) {
                //     params.language = this.language
                // }
                // await this.$http
                //     .get(`admin/case_admin/${event.id}`, {
                //         params: params,
                //         progress(e) {
                //             if (e.lengthComputable) {
                //                 _this.progress = Math.round((e.loaded / e.total) * 100)
                //             }
                //         }
                //     })
                //     .then(res => {
                //         this.case_admin_id = res.body.data.id
                //         this.date_case = res.body.data.expiry_date
                //         this.on_time = res.body.data.expiry_time
                //         this.case_type = res.body.data.case_type.id
                //         this.case_status = (res.body.data.case_status && res.body.data.case_status.id) ? res.body.data.case_status.id : null
                //         this.case_text = res.body.data.text
                //         this.case_text_result = res.body.data.result_text
                //         if(res.body.data.case_status && res.body.data.case_status.id){
                //             this.selectedFiles = [];
                //             res.body.data.documents.forEach((file) => {
                //                 this.selectedFiles.push(file)
                //             })
                //             this.dialogCaseResult = true;
                //         }
                //         else {
                //             this.createEvent(false)
                //         }
                //         this.edit_case = true;
                //
                //     })
                //     .catch(err => {
                //         this.$toastr.error(this.$t('failed_to_get_case_admin'))
                //         if (err && err.body && err.body.message) {
                //             for (let prop in err.body.errors) {
                //                 if (hasOwnProperty.call(err.body.errors, prop)) {
                //                     if (_this.$refs[prop]) {
                //                         _this.$refs[prop].setErrors([
                //                             err.body.errors[prop][0]
                //                         ])
                //                     }
                //                 }
                //             }
                //             if (!err.body.errors) {
                //                 this.$toastr.error(err.body.message)
                //             }
                //         }
                //     })
                //     .finally(end => {
                //         this.progress = 0
                //         this.loading = false
                //     })

            },
            showContextMenu(date, event) {
                if(this.userBlock === true){
                    return;
                }
                event.preventDefault()
                this.menuX = event.clientX;
                this.menuY = event.clientY;
                this.date_case = date.date
                this.showMenu = true;
                document.addEventListener('click', this.closeContextMenu);
            },
            createEvent(create) {
                if(create){
                    this.case_admin_id = null
                    this.on_time = null
                    this.case_type = null
                    this.edit_case = false
                }

                this.dialogCase = true
                this.closeContextMenu();
            },
            deleteEvent() {
                this.closeContextMenu();
            },
            closeContextMenu() {
                this.showMenu = false;
                document.removeEventListener('click', this.closeContextMenu);
            },
            formResult(){
                this.case_status = null;
                this.case_text_result = null;
                this.selectedFiles = [];
                this.result = true;
            },
            async addCaseAdmin() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.case_type) {
                    formData.append('case_type', this.case_type)
                }
                if (this.name) {
                    formData.append('name', this.name)
                }
                if (this.case_text) {
                    formData.append('case_text', this.case_text)
                }

                if (this.responsible && this.responsible.id) {
                    formData.append('responsible', this.responsible.id)
                }


                if (this.participant && this.participant.length > 0) {
                    for (let i in this.participant) {
                        if (this.participant[i].id !== undefined && this.participant[i].id > 0) {
                            formData.append(`participants[${i}]`, this.participant[i].id)
                        }
                    }
                }
                if (this.date_case) {
                    formData.append('timezone', this.date_case)
                }
                if (this.time_from) {
                    formData.append('time_from', this.time_from)
                }
                if (this.time_to) {
                    formData.append('time_to', this.time_to)
                }
                if (this.location_event) {
                    formData.append('location_event', this.location_event)
                }

                if (this.selectedReminderItems && this.selectedReminderItems.length > 0) {
                    for (let i in this.selectedReminderItems) {
                        if (this.selectedReminderItems[i].id !== undefined && this.selectedReminderItems[i].id > 0) {
                            formData.append(`reminders[${i}]`, this.selectedReminderItems[i].id)
                        }
                    }
                }


                if (this.case_status) {
                    formData.append('case_status', this.case_status)
                }


                if(this.$auth.user().id) {
                    formData.append('admin', this.$auth.user().id)
                }









                if(this.$auth.user().id) {
                    formData.append('admin', this.$auth.user().id)
                }



                if (this.case_text_result) {
                    formData.append('case_text_result', this.case_text_result)
                }

                formData.append('id_array', this.id_array)



                if(this.$route.params.id) {
                    if(this.$route.params.id) {
                        switch (this.type){
                            case 'uved':
                                formData.append('uved_uuid', this.$route.params.id)
                                break;
                            case 'deal':
                                formData.append('deal_uuid', this.$route.params.id)
                                break;
                            case 'task':
                                formData.append('task_uuid', this.$route.params.id)
                                break;
                            case 'company':
                                formData.append('company_id', this.$route.params.id)
                                break;
                        }
                    }

                }




                if (this.selectedFiles && this.selectedFiles.length > 0) {
                    let is_image = false;
                    for (let i in this.selectedFiles) {
                        formData.append(`type_mime[${i}]`, this.selectedFiles[i].type)
                        formData.append(`size[${i}]`, this.selectedFiles[i].size)
                        formData.append(`file_name[${i}]`, this.selectedFiles[i].name)
                        formData.append(`is_image[${i}]`, is_image ? 1 : 0)

                        this.selectedFiles[i].blob =  await this.convertBlobToBase64(this.selectedFiles[i].blob)
                        if (this.selectedFiles[i].blob) {
                            let blob = this.dataURL64toBlob(this.selectedFiles[i].blob)
                            if (blob) {
                                formData.append(`upfiles[${i}]`, blob, this.selectedFiles[i].type)
                            }
                        }

                        // formData.append(`upfiles[${i}]`, this.selectedFiles[i].blob)
                    }
                }





                if (this.selectedFiles && this.selectedFiles.length > 0) {
                    let is_image = false;
                    for (let i in this.selectedFiles) {
                        formData.append(`type_mime[${i}]`, this.selectedFiles[i].type)
                        formData.append(`size[${i}]`, this.selectedFiles[i].size)
                        formData.append(`file_name[${i}]`, this.selectedFiles[i].name)
                        formData.append(`is_image[${i}]`, is_image ? 1 : null)

                        this.selectedFiles[i].blob =  await this.convertBlobToBase64(this.selectedFiles[i].blob)
                        if (this.selectedFiles[i].blob) {
                            let blob = this.dataURL64toBlob(this.selectedFiles[i].blob)
                            if (blob) {
                                formData.append(`upfiles[${i}]`, blob, this.selectedFiles[i].type)
                            }
                        }

                        // formData.append(`upfiles[${i}]`, this.selectedFiles[i].blob)
                    }
                }



                if (this.case_admin_id ) {
                    await this.$http
                        .put(`admin/case_admin/${this.case_admin_id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('task_has_been_updated'))
                            this.dialogCase = false
                            this.getCaseAdmin(this.type, this.id_array)
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('task_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
                else {
                    // Add
                    await this.$http
                        .post('admin/case_admin', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('case_admin_has_been_added'))
                            this.dialogCase = false
                            this.getCaseAdmin(this.type, this.id_array)
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('task_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getCaseAdmin(type, id_array) {

                this.loading = true
                let params = {}
                if(this.$route.params.id) {
                    switch (type){
                        case 'uved':
                            params.uved_uuid = this.$route.params.id
                        break;
                        case 'deal':
                            params.deal_uuid = this.$route.params.id
                        break;
                        case 'task':
                            params.task_uuid = this.$route.params.id
                        break;
                        case 'company':
                            params.company_uuid = this.$route.params.id
                        break;
                    }
                }

                if(this.$auth.user().id) {
                    params.admin = this.$auth.user().id
                }
                this.id_array =  id_array
                this.type =  type
                await this.$http
                    .get("admin/case_admin", {
                        params: params,
                    })
                    .then(res => {
                        this.calendarEvents = [];
                        this.case_admin = res.body.data
                        let to_do = this.$moment(new Date()).format('YYYY-MM-DD')
                        for (let i = 0; i < this.case_admin.length; i++) {
                            this.calendarEvents.push(
                                {
                                    id: this.case_admin[i].id,
                                    uuid: this.case_admin[i].uuid,
                                    name: this.case_admin[i].title,
                                    start_at: this.case_admin[i].start_at,
                                    end_at: this.case_admin[i].end_at,
                                    start: this.case_admin[i].date_from,
                                    color: this.case_admin[i].case_status_id > 0 ? 'green' : (to_do > this.case_admin[i].expiry_date ? 'red' : 'blue'),
                                    timed: true,

                                }

                            );
                        }
                    })
                    .catch(err => {
                        this.case_admin = []
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            async getCaseType() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/case_type", {
                        params: params,
                    })
                    .then(res => {
                        this.caseTypeItems = res.body.data
                    })
                    .catch(err => {
                        this.caseTypeItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getCaseStatus() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/case_status", {
                        params: params,
                    })
                    .then(res => {
                        this.caseStatusItems = res.body.data
                    })
                    .catch(err => {
                        this.caseStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },

            closeCaseDialog(){
                this.dialogCase = false
                this.case_text = null
                this.case_text_result = null
                this.selectedFiles = []
                this.case_status = null
                this.exist_translations = {}
                this.case_type = null
                this.date_case = null
                this.on_time = null
                this.location_event = null
                this.location_event_name = null
                this.case_type_name = null
                this.responsible = null
                this.responsibleSearching = null
                this.name = null
                this.participant = null
                this.participantSearching = null
                this.time_from = null
                this.time_to = null
                this.participantItems = []
                this.selectedReminderItems = []
                this.responsibleItems = []


                this.reminderItems .forEach(item => {
                    item.disable = false;
                });




            },

            clearResponsibles() {
                if (!this.responsible) {
                    this.responsibleItems = []
                }
            },
            clearEmptyResponsibleItems(items) {
                if (items && items.length > 0) {
                    this.responsible = items.filter(item => typeof item === 'object')
                }
            },
            async getResponsibles(str) {
                if (str) {
                    this.loadingResponsible = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.administrator = str
                    }
                    await this.$http
                        .get("admin/admin", {
                            params: params,
                        })
                        .then(res => {
                            this.responsibleItems = res.body.data
                        })
                        .catch(err => {
                            this.responsibleItems = []
                        })
                        .finally(end => {
                            this.loadingResponsible = false
                        })
                }
            },

            clearParticipants() {
                if (!this.participant) {
                    this.participantItems = []
                }
            },
            clearEmptyParticipantItems(items) {
                if (items && items.length > 0) {
                    this.participant = items.filter(item => typeof item === 'object')
                }
            },
            async getParticipants(str) {
                if (str) {
                    this.loadingParticipants = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.administrator = str
                    }
                    await this.$http
                        .get("admin/admin", {
                            params: params,
                        })
                        .then(res => {
                            this.participantItems = res.body.data
                        })
                        .catch(err => {
                            this.participantItems = []
                        })
                        .finally(end => {
                            this.loadingParticipants = false
                        })
                }
            },

            selectItem(item) {
                const index = this.selectedReminderItems.indexOf(item);
                if (index === -1) {
                    item.disable = true
                    this.selectedReminderItems.push(item);
                }
                this.menu = false;
            },
            deleteItem(item) {
                const index = this.selectedReminderItems.indexOf(item);
                if (index > -1) {
                    item.disable = false
                    this.selectedReminderItems.splice(index, 1);
                }
                this.menu = false;
            },

            async saveLocationEvent() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.location_event_name) {
                    formData.append('location_event', this.location_event_name)
                }
                formData.append('for_admin', 1)
                // Add
                await this.$http
                    .post('admin/location_event', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('location_event_has_been_added'))
                        this.getLocationEvent()
                        this.closeLocationEvent();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('location_event_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            closeLocationEvent(){
                this.dialogFormLocationEvent = false;
                this.location_event_name = null;

            },

            async saveCaseType() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.case_type_name) {
                    formData.append('case_type', this.case_type_name)

                }
                formData.append('for_admin', 1)
                // Add
                await this.$http
                    .post('admin/case_type', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('case_type_has_been_added'))
                        this.getCaseType()
                        this.closeCaseType();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('case_type_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            closeCaseType(){
                this.dialogFormCaseType = false;
                this.case_type_name = null;

            },
            async getLocationEvent() {
                this.loading = true
                let params = {}
                params.for_admin = 1
                await this.$http
                    .get("admin/location_event", {
                        params: params,
                    })
                    .then(res => {
                        this.locationEventItems = res.body.data
                    })
                    .catch(err => {
                        this.locationEventItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getReminder() {
                this.loading = true
                let params = {}
                params.no_main = 1
                await this.$http
                    .get("admin/reminder_times", {
                        params: params,
                    })
                    .then(res => {
                        this.reminderItems = res.body.data
                    })
                    .catch(err => {
                        this.reminderItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },

            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },

        },
    }
</script>

